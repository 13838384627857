import { writable } from "svelte/store";

/*                                                          */
export const openSheetID = writable<string | null>(null);
export const openSheet = writable<HTMLOcSheetV1Element | null>();
export const sheetHistory = writable<string[]>([]);
export const isBackNavigation = writable(false);
export const documentScrollingDisabled = writable(false);
export const activeElement = writable<HTMLElement | null>(null);

/*               */
export function goBack(): void {
  let previousSheetID: string | undefined;
  sheetHistory.update((history) => {
    previousSheetID = history.pop();
    return history;
  });
  if (previousSheetID) {
    openSheetID.set(previousSheetID);
    isBackNavigation.set(true);
  }
}

export function getHeader(sheet: HTMLOcSheetV1Element): HTMLElement | null {
  return sheet.shadowRoot!.querySelector(".sheet__header") ?? null;
}

export function isElement(value: ChildNode): value is Element {
  return value.nodeType === 1;
}

export function getContent(sheet: HTMLOcSheetV1Element): HTMLElement | null {
  const container = document.createElement("div");
  Array.from(sheet.childNodes).forEach((child) => {
    if (isElement(child)) {
      const isNotSlotElement = !child.getAttributeNode("slot");
      if (isNotSlotElement) {
        /*                                                      */
        /*                                                                */
        container.append(child.cloneNode(true));
      }
    } else {
      container.append(child.cloneNode(true));
    }
  });
  return container.children.length ? container : null;
}
export function getActions(sheet: HTMLOcSheetV1Element): HTMLElement | null {
  return sheet.querySelector("[slot=actions]");
}

export async function loadContent(url: string): Promise<string> {
  const response = await fetch(url, { redirect: "manual" });
  if (!response.ok) {
    const error = new Error("fetch response is not ok") as Error & { status: number };
    /*                                                                                          */
    error.status = response.status || 300;

    throw error;
  }
  return response.text();
}
